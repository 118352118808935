/* You can add global styles to this file, and also import other style files */
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.cv-fullsize-content {
  width: 100%;
  height: 100%;
}

.cv-center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cv-right-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.cv-flexwrap-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.cv-center-items {
  align-items: center;
  justify-content: center;
}

.cv-hidden {
  visibility: hidden;
}

.cv-hidden-box {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.cv-zerobox-content {
  padding: 0 !important;
  margin: 0 !important;
}

.cv-side-by-side-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.cv-hyperlink {
  text-decoration: none !important;
  cursor: pointer !important;
}

.cv-hyperlink-hover:hover {
  font-weight: bold;
}

.cv-page-title {
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
}

.cv-border-content {
  border: 1px solid;
}

.cv-round-border {
  border: 1px solid;
  border-radius: 0.5rem;
}

.cv-circle-border {
  border: 1px solid;
  border-radius: 50%;
}

.cv-buttongroup-item {
  padding-right: 0.25rem !important;
}

.cv-vbox-item {
  padding-top: 1rem;
}

.cv-hbox-item {
  padding-left: 1rem;
}

.cv-form-toolbar-buttons {
  padding-top: 1.2rem;
}

.cv-form-switch,
.cv-form-item {
  padding-top: 0.5rem;
}

.cv-form-item-readonly {
  padding: 0.5rem;
  border: 1px dashed;
}

.cv-right-aligned {
  text-align: right;
}

.cv-center-aligned {
  text-align: center;
}

.cv-text-justified {
  text-align: justify;
}

.cv-side-by-side-items {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.cv-hspacer {
  padding: 0 0.5rem;
}

.cv-hruler {
  width: 100%;
  margin-top: 0.5rem;
  border-top: 1px solid;
}

.cv-vspacer {
  padding: 0.5rem;
}

.cv-vruler {
  height: 100%;
  margin-left: 0.5rem;
  border-left: 1px solid;
}

.cv-actions-cell-template {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cv-cell-template-icon {
  .dx-button-content .dx-icon {
    font-size: 1.5rem;
  }
}

.cv-html-editor-fa-icons {
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
  padding-right: 1px !important;
  font-size: 16px !important;
}

.cv-zoom-125:hover {
  transform: scale(1.25);
}

.cv-zoom-150:hover {
  transform: scale(1.5);
}

.cv-zoom-175:hover {
  transform: scale(1.75);
}

.cv-transparent-background {
  background-color: transparent !important;
}

.cv-printable-content {
  padding: 0.5rem 2rem;
  .cv-unprintable-content {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.cv-user-select {
  cursor: text !important;
}

.cv-custom-message-content {
  min-width: 250px;
  max-width: 350px;
  min-height: 50px;
  white-space: normal;
  cursor: text !important;
}

.cv-fullscreen-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important;
}

/* Participant Login same for all themes */
.cv-participant-auth-base-container {
  .cv-participant-login-content {
    background-image: repeating-linear-gradient(
      135deg,
      #ffffff,
      #d4d4d4 75%,
      #c8c8c8 100%
    ) !important;
    border-color: #c8c8c8 !important;
  }
}
/* Participant Login End */

/* Header Marquee Begin */
.cv-marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  .cv-marquee-content {
    display: inline-block;
    padding-right: 5rem;
    animation: cv-marquee 10s linear infinite;
  }
}

@keyframes cv-marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
/* Header Marquee End */

/* Custom Font Sizes Begin */
.cv-1rx {
  font-size: 1rem;
}

.cv-1-1rx {
  font-size: 1.1rem;
}

.cv-1-25rx {
  font-size: 1.25rem;
}

.cv-1-5rx {
  font-size: 1.5rem;
}

.cv-1-75rx {
  font-size: 1.75rem;
}

.cv-2rx {
  font-size: 2rem;
}
/* Custom Font Sizes End */

/* File Type Icon Colors Begin */
.cv-icon-pdf {
  color: #c81e26;
}

.cv-icon-docx {
  color: #2c569b;
}

.cv-icon-xlsx {
  color: #107a0e;
}

.cv-icon-pptx {
  color: #e47148;
}

.cv-icon-jpg {
  color: #6cb9f1;
}

.cv-icon-png {
  color: #d27ef3;
}

.cv-icon-gif {
  color: #4ecdc4;
}

.cv-icon-bmp {
  color: #c1e621;
}

.cv-icon-zip {
  color: #ffd900;
}

.cv-icon-rar {
  color: #ffd900;
}
/* File Type Icon Colors End */

/* Splitter Overrides Begin */
.as-split-area {
  overflow: hidden !important;
}
/* Splitter Overrides End */

/* Dx Overrides Begin */
.dx-toolbar-center {
  margin: 0 auto !important;
  float: unset !important;
}
.dx-popup-content {
  padding: 1rem;
}
.dx-popup-bottom.dx-toolbar {
  padding: 1rem;
}

.dx-datagrid,
.dx-treelist {
  .dx-treelist-content .dx-treelist-table .dx-row > td,
  .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    vertical-align: middle;
  }
  .dx-treelist-content .dx-treelist-table .dx-row > td[role='columnheader'],
  .dx-datagrid-content .dx-datagrid-table .dx-row > td[role='columnheader'] {
    vertical-align: top;
  }
  .dx-treelist-text-content.dx-header-filter-indicator,
  .dx-datagrid-text-content.dx-header-filter-indicator,
  [role='columnheader'] {
    text-align: left !important;
  }
  .dx-column-indicators {
    float: right !important;
  }
  .dx-command-select[role='columnheader'] {
    vertical-align: middle !important;
    text-align: center !important;
  }
  .dx-datagrid-header-panel .dx-toolbar {
    margin-bottom: unset !important;
    padding: 0.25rem 0;
  }
  .dx-treelist-cell-expandable {
    .dx-treelist-icon-container {
      padding: 0 0.5rem;
    }
    .dx-treelist-text-content
      > .dx-template-wrapper:not(.cv-actions-cell-template) {
      padding: 0.5rem 0;
    }
  }
}

.dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
  padding: 0 !important;
  .dx-datagrid-group-closed,
  .dx-datagrid-group-opened {
    font-size: 1.75rem;
    height: 1rem;
  }
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
  padding: 0.5rem;
}

.dx-overlay-content.dx-inner-overlay.dx-context-menu.dx-menu-base {
  min-width: 120px;
  .dx-menu-items-container {
    width: 100%;
  }
}

.dx-pager {
  padding: 0.25rem 0 0 0;
  .dx-light-mode {
    .dx-page-sizes {
      min-width: 42px !important;
    }
    .dx-page-index {
      min-width: 32px !important;
    }
  }
}

.dx-treelist-rowsview .dx-treelist-empty-space,
.dx-treelist-rowsview .dx-treelist-collapsed,
.dx-treelist-empty-space .dx-treelist-expanded {
  height: 32px;
}

.dx-treelist-rowsview .dx-treelist-collapsed span::before {
  content: '\f0fe' !important;
}

.dx-treelist-rowsview .dx-treelist-expanded span::before {
  content: '\f146' !important;
}

.dx-treeview-expander-icon-stub {
  display: none !important;
}

.dx-loadpanel-wrapper {
  z-index: 5100 !important;
  .dx-loadpanel-message {
    padding-top: 0.75rem;
    font-size: 1rem;
    font-weight: 400;
  }
}

.dx-menu-vertical {
  .dx-menu-items-container.dx-menu-no-icons {
    width: 100%;
  }
}

.dx-device-desktop {
  min-width: 450px;
  min-height: 600px;
  overflow: hidden;
}

@media screen and (min-width: 1921px) {
  .dx-device-desktop {
    /* Setting max width * height to full hd resolution */
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  }
}
/* Dx Overrides End */

/* TinyMCE Overrides Begin */
.tox-tinymce-aux {
  z-index: 3300 !important;
}
/* TinyMCE Overrides End */
